import React from "react";
import Header from "./header";
import Footer from "./footer";
import Banner from "./banner";
import "../styles/layout.css";

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleBodyClass);
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleBodyClass);
  }

  toggleBodyClass = () => {
    if (this.state.scrolled && window.scrollY <= 10) {
      this.setState({ scrolled: false });
    } else if (!this.state.scrolled && window.scrollY > 10) {
      this.setState({ scrolled: true });
    }
  };

  render() {
    const {
      children,
      onHideNav,
      onShowNav,
      showNav,
      siteTitle,
      navMenuItems,
      textWhite = true,
      nav,
      footer,
      announcement
    } = this.props;

    const { scrolled } = this.state;
    return (
      <>
        {announcement?.enabled && <Banner announcement={announcement} />}
        <Header
          navMenuItems={navMenuItems}
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          scrolled={scrolled}
          textWhite={textWhite}
          nav={nav}
          hasAnnouncement={announcement?.enabled}
        />
        <>{children}</>
        <Footer {...footer} />
      </>
    );
  }
}

export default Layout;
