import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons"
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

const SocialIcon = ({ href, _type }) => {
  let icon
  switch (_type) {
    case 'instagram':
      icon = faInstagram
      break
    case 'facebook':
      icon = faFacebookF
      break
    case 'twitter':
      icon = faTwitter
      break
  }
  return (
    <li className="mt-2 inline-block mr-2 md:mr-5 md:block">
      <a href={href} target="_blank">
        <FontAwesomeIcon icon={icon} size="lg" />
      </a>
    </li>
  )
}

const Footer = ({ heading, subheading, _rawSocial, email, phone, address, image }) => {
  const img = image && imageUrlFor(buildImageObj(image))
  .width(1600)
  .url()
  const socials = (_rawSocial || [])
    .map(social => <SocialIcon key={social._key} {...social} />)

  return (
    <footer className="bg-gray-800 pt-16">
      <div className="mx-auto container mb-8 px-8">
        <div className="w-full flex flex-wrap flex-col md:flex-row py-6">
          <div className="flex-1 mb-6 justify-center text-center">
            <div className="w-24 text-center m-auto mb-5">
              <img className="w-full" src={img} />
            </div>
            <h3 className="uppercase font-medium text-2xl tracking-widest">{heading}</h3>
            <p className="text-green-800 font-extralight text-xl">{subheading}</p>
          </div>

          <div className="flex-none">
            <div className="flex-1">
              <div className="flex flex-wrap text-center md:text-left justify-center md:justify-end">
                <p className="text-white font-thin mb-0 break-normal w-2/3 md:w-1/3">{address}</p>
                <ul className="text-center md:text-right mb-6">
                  <li className="inline-block mr-2 md:block md:mr-0">
                    <a href={`mailto:${email}`} className="no-underline text-green-800">
                      {email}
                    </a>
                  </li>
                  <li className="inline-block mr-2 md:block md:mr-0">
                    <a href={`tel:${phone}`} className="no-underline text-green-800">
                      {phone}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="flex flex-wrap w-full justify-center md:justify-end">
              <ul className="flex justify-center w-full md:justify-start md:w-2/5">
                {socials}
              </ul>
              <p className="text-white text-sm pb-2">© {new Date().getFullYear()} Grizzly's Male Hair Salon</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
