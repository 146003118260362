import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";

// https://ogp.me

function SEO({ lang, meta, title, bodyAttr, schema }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const image = data?.site?.openGraph?.image
        const metaDescription = (data?.site?.openGraph?.description) || "";
        const siteTitle = (data.site && data.site.name) || "";
        const metaImage =
          image && image.asset
            ? imageUrlFor(buildImageObj(image))
              .width(1200)
              .url()
            : "";
        const favicon = data?.site?.openGraph?.favicon?.asset?.url

        const pageTitle = title || siteTitle;
        const keywords = data.keywords || [];

        const scripts = [{
          type: 'text/javascript',
          src: 'https://unpkg.com/smoothscroll-polyfill@0.4.4/dist/smoothscroll.min.js',
          defer: true
        }].concat(...schema ? [{
          type: "application/ld+json",
          innerHTML: JSON.stringify(schema)
        }] : [])

        return (
          <Helmet
            bodyAttributes={bodyAttr}
            htmlAttributes={{ lang }}
            title={pageTitle}
            titleTemplate={pageTitle === siteTitle ? siteTitle : `%s | ${siteTitle}`}
            meta={[
              {
                name: "description",
                content: metaDescription
              },
              {
                property: "og:title",
                content: title
              },
              {
                property: "og:description",
                content: metaDescription
              },
              {
                property: "og:type",
                content: "website"
              },
              {
                property: "og:image",
                content: metaImage
              },
              {
                name: "twitter:card",
                content: "summary"
              },
              {
                name: "twitter:creator",
                content: 'grizzlysmhs'
              },
              {
                name: "twitter:title",
                content: title
              },
              {
                name: "twitter:description",
                content: metaDescription
              }
            ]
              .concat(
                keywords && keywords.length > 0
                  ? {
                    name: "keywords",
                    content: keywords.join(", ")
                  }
                  : []
              )
              .concat(meta)}
            link={[
              {
                "rel": "icon",
                "type": "image/png",
                "href": favicon
              }
            ]}
            script={scripts}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  schema: {}
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  schema: PropTypes.object,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      name
      keywords
      openGraph {
        title
        description
        image {
          ...SanityMainImage
        }
        favicon {
          asset {
            url
          }
        }
      }
    }
  }
`;
