import { Link } from "gatsby";
import React, { useState } from "react";
import CTALink from "./CTALink";

const Header = ({ nav, scrolled, siteTitle, textWhite = true, hasAnnouncement = false }) => {
  const [showMenu, setShowMenu] = useState(false);

  let headerClass = `md:fixed ${hasAnnouncement && 'md:mt-16'} ${hasAnnouncement && showMenu && '-mt-16'} w-full bg-white md:bg-transparent text-black z-30 top-0`;
  if (scrolled) {
    headerClass = `md:fixed w-full z-30 top-0 md:bg-gray-800 md:text-white`
  }

  let navActionClass =
    "mx-auto lg:mx-0 hover:underline font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow opacity-75";
  navActionClass += !textWhite || !scrolled ? " bg-white text-gray-800" : "";
  navActionClass += textWhite || scrolled ? ` gradient ${textWhite ? 'text-white' : 'text-black'}` : "text-white";

  let titleClass = `flex h-20 capitalize text-black ${textWhite ? 'md:text-white' : 'md:text-black'} no-underline hover:no-underline font-bold text-2xl lg:text-4xl mt-2 md:ml-4 ${showMenu ? 'text-white' : ''}`;
  if (scrolled) {
    titleClass = 'flex h-20 capitalize no-underline hover:no-underline font-bold text-2xl lg:text-4xl mt-2 md:ml-4 text-white'
  }

  function handleOpenMenu (state) {
    setShowMenu(state)

    if (state) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }

  const { title, items: navMenuItems, imageWhite, imageBlack } = nav || {}
  const urlDark = imageBlack?.asset?.url
  const urlLight = imageWhite?.asset?.url

  let url = imageWhite?.asset?.url
  if (!textWhite) {
    url = imageBlack?.asset?.url
  }
  if (scrolled) {
    url = imageWhite?.asset?.url
  }

  function hasBigWord (title) {
    return title.split(' ').reduce((acc, cur) => {
      if (cur.length > 10) return true
      return acc
    }, false)
  }

  return (
    <nav id="header" className={headerClass}>
      <div className="w-full mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className={`md:pl-4 flex items-center ${showMenu ? 'z-40' : ''}`}>
          <Link id="siteTitle" className={titleClass} to="/">
            <div className="w-12 h-12 ml-4 flex content-center align-center self-center justify-center">
              {/* Desktop Logo */}
              <img
                className="hidden md:block self-center justify-center"
                src={url}
                alt="Grizzly Logo"
              />

              {/* Mobile Logo */}
              <img
                className="md:hidden self-center justify-center"
                src={scrolled ? urlLight : showMenu ? urlLight : urlDark}
                alt="Grizzly Logo"
              />
            </div>
            <h4 className="uppercase tracking-wider text-2xl ml-4 self-center">{title || siteTitle}</h4>
          </Link>
        </div>

        <button
          className={`mr-8 z-40 border-4 font-semibold py-1 px-6 sm:block md:hidden ${scrolled ? 'border-white' : 'border-black'} ${showMenu ? 'text-white border-white' : 'border-black'}`}
          aria-label="Toggle menu on/off"
          onClick={() => handleOpenMenu(!showMenu)}
        >
          Menu
        </button>

        {navMenuItems && (
          <>
            {/* Desktop Menu */}
            <div className="hidden md:block lg:mr-4">
              <ul className="grid grid-flow-col tracking-tight text-sm lg:text-base lg:gap-4 items-center">
                {navMenuItems.map((i, k) => (
                  <li key={k} className={`${hasBigWord(i.title) ? 'w-32' : 'w-24'} text-center font-bold justify-center ${textWhite ? 'text-white' : scrolled ? 'text-white' : 'text-black'} border-transparent duration-500 border-b-4 transition-all hover:border-green-800 font-medium`}>
                    <CTALink {...i} buttonActionClass={navActionClass} />
                  </li>
                ))}
              </ul>
            </div>

            {/* Mobile Menu */}
            <div className={`md:hidden z-30 bg-gray-800 text-white w-full h-screen absolute space-y-6 py-24 px-2 inset-y-0 left-0 transform ${!showMenu && '-translate-x-full'} transition duration-300 ease-in-out`}>
              <ul className="flex flex-col w-full items-start">
                {navMenuItems.map((i, k) => (
                  <li key={k} onClick={() => handleOpenMenu(false)} className={
                    `py-3 px-2 w-full border-opacity-10 border-t ${k === navMenuItems.length - 1 && 'border-b'}`
                  }>
                    <CTALink {...i} className={`w-full block`} />
                  </li>
                ))}
              </ul>
            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Header;
